// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


import {getDatabase} from "firebase/database";
import "firebase/auth";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYGBENfv0u0j8tQ8yCtBCKShIy2N5H7Mw",
  authDomain: "easybox-cb391.firebaseapp.com",
  projectId: "easybox-cb391",
  storageBucket: "easybox-cb391.appspot.com",
  messagingSenderId: "27769716068",
  appId: "1:27769716068:web:c252fd025035ab82220e71",
  measurementId: "G-DQWQPFDGQX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app)
export const db = getDatabase(app);