
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Map from './pages/Map';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
         
          <Route path='/' element={<Login />} />
          <Route path='/home' element={<Home />} />
          <Route path='/map' element={<Map />} />


          
         

        </Routes>
      </BrowserRouter>
   
    </div>
  );
}

export default App;
