import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import "../src/main/Styles/index.css";
import "../src/main/Styles/Components/Header.css";
import "../src/main/Styles/MapBox.css";
import "../src/main/Styles/TextField.css";
import "../src/main/Styles/Components/Buttons.css";
import "../src/main/Styles/Components/TopBar.css";
import "../src/main/Styles/Components/DistanceBox.css"


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
serviceWorker.unregister();

