import React from 'react'
import Topbar from '../../components/topbar/Topbar'
import Map from '../Map'
import './home.css'

export default function Home() {
  return (
    <div className='homeWrapper'>
        <Topbar />
        <div className="homeBody">
        <Map />
        </div>
       

    </div>
  )
}
