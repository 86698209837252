import {React,useState,useEffect} from 'react'
import './topbar.css'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/Store';
import MopedIcon from '@mui/icons-material/Moped';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HelpIcon from '@mui/icons-material/Help';
import QuizIcon from '@mui/icons-material/Quiz';
import { useNavigate } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Auth, signInWithEmailAndPassword } from 'firebase/auth'

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

export default function Topbar() {
    const navigate =  useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [email,setEmail] = useState('');
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorE2);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorE2(event.currentTarget);
      };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorE2(null);
      };


const signout = () =>{
  signOut(auth).then(()=>{
    navigate('/');
  }).catch(error=>console.log(error))
  
}
var username = '';


useEffect(() =>{
  auth.onAuthStateChanged((user) =>{
    if(user){
        
       setEmail(user.email)
        
    }
   
  })
  
  
 
},[])
  return (
    <div className='topbarWrapper'>
        <div className="topbarLeft">
            <div className="dots">
           

            </div>
          
           
            <img src="/assets/easyboxLogo.png" className='logo' alt="" />

        </div>
        
        <div className="topbarRight">
              <div className="newOrdercont">
                  <a className='newOrder' href='https://www.admin.easybox.ke/orders' target='_blank' >Order Management</a>
            </div>
            

            
            <p className='adminTitle'>Store</p>
            <img src="/assets/accountIcon.png" className='accountImg' alt="" />
            {/* <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      > */}
        <ArrowDropDownIcon onClick={handleClick} style={{fontSize:'30px',marginLeft:'3%'}} />
    
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
       <p className='loggedIn'>{email}</p>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem disableRipple>
          <ReportIcon />
          Report an Issue
        </MenuItem>
        <MenuItem onClick={signout} disableRipple>
          <LogoutIcon />
          Log out
        </MenuItem>
      </StyledMenu>
            
            

        </div>

    </div>
  )
}
